"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfClientConversationPayment = void 0;
;
const instanceOfClientConversationPayment = (object) => {
    return ('Payment' in object &&
        'ConversationPayment' in object &&
        'Paid' in object &&
        'Files' in object);
};
exports.instanceOfClientConversationPayment = instanceOfClientConversationPayment;
