import { Link, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState, useEffect, useContext } from "react";
import { ClientConversationPayment, instanceOfClientConversationPayment } from "shared-models/build/client/payment_message";
import { CURRENCIES } from "shared-models/build/constants";
import { FirebaseAuthContext } from "../../../context/firebase-auth";
import { sendPostRequest } from "../../../util/util";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { ImSpinner } from "react-icons/im";
import { loadStripe, StripeElementsOptions, Appearance } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "../../../components/CheckoutForm";

interface Props {
    id: string, // This is the conversation id
}

const stripePublishableKey = process.env.GATSBY_STRIPE_PUBLISHABLE_KEY || "";
const stripePromise = loadStripe(stripePublishableKey);

const ConfirmPaymentPage = ({ id }: Props) => {
    const user = useContext(FirebaseAuthContext);
    // Payments
    const defaultPayment = {
        Id: 0,
        ReferenceName: "",
        OrganizationId: 0,
        Amount: 0,
        Description: "",
        Currency: CURRENCIES.USD,
        PriceBreakdown: [],
        CancellationPolicyId: 0,
        RefundPolicyId: 0,
        TripDetailsId: 0,
        ExtraDetailsId: 0,
        CancellationPolicyDescription: "",
        RefundPolicyDescription: "",
        TripStartDate: new Date(),
        TripEndDate: new Date(),
        Expiry: new Date(),
    }
    const defaultClientConversationPayment = {
        ConversationPayment: {
            ConversationId: 0,
            PaymentId: 0,
            Cancelled: false,
            CreatedAt: new Date(),
        },
        Paid: false,
        Payment: { ...defaultPayment },
        Files: [],
    }
    const [paymentDetails, setPaymentDetails] = useState<ClientConversationPayment>(defaultClientConversationPayment);
    const [fileMap, setFileMap] = useState<{ [key: number]: string }>({});
    const [agreed, setAgreed] = useState<boolean>(false);
    const [loadingPayment, setLoadingPayment] = useState<boolean>(true);
    const [clientSecret, setClientSecret] = useState<string>("");
    const [clientSecretAmount, setClientSecretAmount] = useState<number>(0);
    const [clientSecretCurrency, setClientSecretCurrency] = useState<string>("");

    // Get the conversation payment details
    useEffect(() => {
        getPayment();
    }, [user]);

    const getPayment = async () => {
        if (user === null) return;
        setPaymentDetails(defaultClientConversationPayment);
        
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        let paymentId = params.get("payment_id");
        if (paymentId === null) {
            return;
        }

        const postData = {
            ConversationId: id,
        };
        
        const [paymentDetails, paymentErr] = await sendPostRequest(user, "/payments/get-operator-user-payment", postData, "", "Could not get the payment details.");
        setLoadingPayment(false);
        if (paymentErr !== null) {
            navigate("/");
            return;
        } else {
            
            // TODO: using the same URL as the conversation page, this can be optimized to retrieve one payment only
            if (Array.isArray(paymentDetails)) {
                const pd : ClientConversationPayment = paymentDetails.find((val) => {
                    if (instanceOfClientConversationPayment(val)) {
                        if (val.Payment.Id === +String(paymentId)) {
                            return val;
                        }
                    }
                })
                setPaymentDetails(pd);
                const fileById : { [key: number]: string } = pd.Files.reduce((obj, fl) => {
                    return {
                        ...obj,
                        [fl.Id]: fl.FileUrl,
                    };
                }, {});
                setFileMap(fileById);
            }
        }
    }

    const getStripeSecret = async () => {
        if (user === null) {
            alert("Please login or sign up.");
            return;
        }
        const [clientSecret, err] = await sendPostRequest(user, "/payment-intents/create-stripe-payment-intent", { Agreed: agreed, PaymentId: paymentDetails.Payment.Id }, "", "Could not get the payment information.");
        if (err !== null) {
            console.error(err);
            return;
        } else if ("clientSecret" in clientSecret) {
            setClientSecret(clientSecret.clientSecret);
            setClientSecretAmount(clientSecret.amount);
            setClientSecretCurrency(clientSecret.currency);
        } else {
            console.log("No client secret in the return response.")
        }
    }

    const appearance : Appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: "#7E8131",
        }
    };
    const options : StripeElementsOptions = {
        clientSecret,
        appearance,
    };
    

    if (loadingPayment) {
        return (
            <div className="tw-p-6 lg:tw-p-8">
                {/* CloudSafari Logo */}
                <div className="tw-flex tw-justify-center tw-w-full">
                    <Link to="/">
                        <StaticImage src="../../../images/cs-logo-with-text.svg" imgClassName="tw-max-w-[180px]" objectFit="contain" alt="CloudSafari" loading="eager" placeholder="none" />
                    </Link>
                </div>

                {/* Spinner */}
                <div className="tw-flex tw-w-full tw-justify-center tw-mt-8">
                    <ImSpinner className="tw-text-3xl tw-animate-spin" />
                </div>
            </div>
        )
    }

    return (
        <div className="tw-p-6 lg:tw-p-8">

            {/* CloudSafari Logo */}
            <div className="tw-flex tw-justify-center tw-w-full">
                <Link to="/">
                    <StaticImage src="../../../images/cs-logo-with-text.svg" imgClassName="tw-max-w-[180px]" objectFit="contain" alt="CloudSafari" loading="eager" placeholder="none" />
                </Link>
            </div>

            {/* Payment Details */}
            <div className="tw-flex tw-justify-center">
                <div className="tw-flex tw-justify-between tw-flex-wrap tw-py-8 tw-w-full tw-max-w-[1400px]">

                    {/* Left Column */}
                    <div className="tw-w-full lg:tw-w-1/2">
                        <h3 className="tw-text-3xl tw-font-light">Confirm and pay</h3>
                        <h4 className="tw-text-lg tw-font-semibold tw-mt-8">Your trip details</h4>
                        <p className="tw-text-normal tw-mt-4">
                            Dates: {new Date(paymentDetails.Payment.TripStartDate).toDateString()} - {new Date(paymentDetails.Payment.TripEndDate).toDateString()}
                        </p>
                        <a 
                        target="_blank"
                        rel="noreferrer"
                        className="tw-text-safari-green tw-flex tw-items-center tw-mt-4"
                        href={fileMap[paymentDetails.Payment.TripDetailsId]}>
                            <span className="tw-pr-2">Your Trip Details</span> <BsBoxArrowUpRight />
                        </a>
                        <h3 className="tw-mt-4 tw-font-semibold tw-text-lg">Total Price: {paymentDetails.Payment.Currency === "USD" ? "$" : "€"}{paymentDetails.Payment.Amount} {paymentDetails.Payment.Currency} </h3>

                        <h3 className="tw-text-lg tw-font-semibold tw-mt-12">Cancellation Policy</h3>
                        <a href={fileMap[paymentDetails.Payment.CancellationPolicyId]} className="tw-mt-4 tw-flex tw-items-center tw-text-safari-green"><span className="tw-pr-2">Cancellation Policy</span> <BsBoxArrowUpRight /></a>
                        <p className="tw-text-sm tw-mt-4">
                            {paymentDetails.Payment.CancellationPolicyDescription}
                        </p>
                        
                        <h3 className="tw-text-lg tw-font-semibold tw-mt-12">Refund Policy</h3>
                        <a href={fileMap[paymentDetails.Payment.RefundPolicyId]} className="tw-mt-4 tw-flex tw-items-center tw-text-safari-green"><span className="tw-pr-2">Refund Policy</span> <BsBoxArrowUpRight /></a>
                        <p className="tw-text-sm tw-mt-4">
                            {paymentDetails.Payment.RefundPolicyDescription}
                        </p>

                        <h3 className="tw-text-lg tw-font-semibold tw-mt-12">CloudSafari's Policy</h3>
                        <p className="tw-text-sm tw-mt-4">
                        As part of CloudSafari’s policy, you have up to 24 hours to reverse your payment after paying. You’ll be refunded back the full amount less the money processing fees (2.9% + $30).
                        </p>
                        <p className="tw-text-sm tw-mt-2">
                        Once your payment is received, we confirm with the company you're booking with and fulfill the order to make sure everything is in order for your trip.
                        </p>

                    </div>


                    {/* Payment Details */}
                    <div className="tw-w-full lg:tw-w-[45%] tw-mt-12 lg:tw-mt-0">

                        {/* Price Breakdown */}
                        <div className="tw-border-2 tw-p-8 tw-rounded-lg">
                            <h3 className="tw-text-lg tw-font-semibold tw-my-4">Price Details</h3>
                            {
                                paymentDetails.Payment.PriceBreakdown.map((val) => {
                                    return (
                                        <div className="tw-flex tw-justify-between tw-items-center tw-pb-4">
                                            <h3 className="tw-text-normal">{val.name}</h3>
                                            <h3 className="tw-text-normal">{paymentDetails.Payment.Currency === "USD" ? "$" : "€"}{val.price}</h3>
                                        </div>
                                    )
                                })
                            }
                            <div className="tw-h-[2px] tw-bg-gray-200 tw-w-full tw-rounded tw-my-4" />
                            <div className="tw-mt-4 tw-flex tw-justify-between">
                                <h3 className="tw-font-semibold tw-text-lg">Total:</h3>
                                <h3 className="tw-font-semibold tw-text-lg">{paymentDetails.Payment.Currency === "USD" ? "$" : "€"}{paymentDetails.Payment.Amount} {paymentDetails.Payment.Currency}</h3>
                            </div>
                        </div>


                        {/* Payment */}
                        {
                            paymentDetails.Paid ?
                            <div className="tw-mt-8 tw-border-2 tw-p-8 tw-rounded-lg">
                                This invoice has already been paid! Please send us an email at <a className="tw-text-safari-green" href="mailto:hello@mycloudsafari.com">hello@mycloudsafari.com</a> if you have any questions.
                            </div>
                            :
                            clientSecret.length > 0 ?
                            <div className="tw-mt-8 tw-border-2 tw-p-8 tw-rounded-lg">
                                <Elements options={options} stripe={stripePromise}>
                                    <CheckoutForm />
                                </Elements>
                                <div className="tw-text-center tw-w-full">
                                    <small>You will be charged {(clientSecretAmount/100).toFixed(2)} {clientSecretCurrency.toUpperCase()}</small>
                                </div>
                            </div>
                            :
                            <div className="tw-mt-8 tw-border-2 tw-p-8 tw-rounded-lg">
                                <div className="tw-flex">
                                    <p className="tw-pb-2"><input type="checkbox" checked={agreed} onChange={(e) => setAgreed(e.target.checked)} /> I agree to the cancellation policy, refund policy, and policy as set by the company facilitating my trip and CloudSafari, Inc.</p>
                                </div>
                                <button 
                                    onClick={() => {
                                        if(!agreed) {
                                            alert("You must agree to the terms in order to proceed.");
                                            return;
                                        } else {
                                            getStripeSecret();
                                        }
                                    }}
                                    className={`tw-primary-button tw-mt-8 tw-w-full ${agreed ? "" : "tw-opacity-50 tw-cursor-not-allowed"}`}>
                                    Confirm Payment of {paymentDetails.Payment.Currency === "USD" ? "$" : "€"}{paymentDetails.Payment.Amount} {paymentDetails.Payment.Currency}
                                </button>
                                <div className="tw-w-full tw-text-center">
                                <small>You will not be charged yet</small>
                                </div>
                            </div>
                        }

                    </div>

                </div>
            </div>

        </div>
    )
}

export default ConfirmPaymentPage;